import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import { withRouter } from 'next/router';
import amplitude from 'amplitude-js';
import _throttle from 'lodash.throttle';
import { fetchClearbitUserData } from ':lib/clearbit';
import { filterFalsyObjectKeys } from ':helpers/object-helpers';
import { postToOpenpriseProxy } from ':lib/openprise';
import { formDataToObject, getDemoDateTime } from ':helpers/form-helpers';
import { handleOptimizelyGTMTracking } from ':lib/optimizely';
import { capitalize, getUrlParam, recursiveMap } from '../../helpers';
import {
  get30Days,
  getXHoursFromNow,
  dateToDateStr,
} from '../../../helpers/date-helpers';

const emailRegex = /^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
const countryCodeRegex = /^(\+?\d{1,3}\s|\d{1,4}\s).*$/;
const standardEmail = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;

const validateEmail = (email) => {
  if (!email?.length > 3) {
    return false;
  }
  return emailRegex.test(email);
};


const fetchFormDataWithCb = async (email, cb) => {
  if (!validateEmail(email)) {
    cb(null);
    return;
  }
  const clearbitUserData = await fetchClearbitUserData(email);
  if (!clearbitUserData) {
    cb(null);
    return;
  }
  const formData = {
    user_email: email,
    Country: clearbitUserData.geo.country,
    user_company: clearbitUserData.name,
  };
  cb(formData);
};
  

const getOptimizelyExperimentsFromCookies = (cookies) => {
  let experimentCookies = '';
  const allCookies = cookies.getAll();

  Object.entries(allCookies).forEach(([key, cookie]) => {
    const experiment = key.match(/opt_(.*)_user/)?.[1];

    if (experiment) {
      const variation = cookies.get(`opt_${experiment}_variation`);

      experimentCookies += `${experiment}:${variation},`;
    }
  });

  return experimentCookies;
};

const throttledFetchUserFormData = _throttle(fetchFormDataWithCb, 500, {
  trailing: true,
});

const getFields = (
  data,
  fields,
  touched,
  steps,
  dependencies,
  isClearbitForm
) => {
  const cookies = new Cookies();
  const children = data.innerBlocks || [];
  
  for (let i = 0; i < children.length; i++) {
    const child = children[i];

    if (child.attributes.alias === 'Step') {
      const { dependency } = child.attributes.data;

      if (dependency) {
        dependencies.push(dependency);
      } else {
        dependencies.push(null);
      }

      steps.push([]);
    }

    if (
      child.name === 'field' ||
      child.name === 'field2' ||
      child.name === 'select' ||
      child.name === 'radios'
    ) {
      const cookieValue = cookies.get(child.attributes.name);
      const clearbitCookieValue = cookies.get(
        `clearbit_${child.attributes.name}`
      );

      // We only want to use clearbit cookies as defaults if it's a clearbit form
      // and we don't want to use our standard form cookie values to prefill clearbiti forms
      if (isClearbitForm && clearbitCookieValue) {
        fields[child.attributes.name] = clearbitCookieValue;
        touched[child.attributes.name] = true;

        cookies.remove(`clearbit_${child.attributes.name}`);
      } else if (!isClearbitForm && cookieValue) {
        fields[child.attributes.name] = cookieValue;
        touched[child.attributes.name] = true;
      } else {
        fields[child.attributes.name] = '';
      }

      const step = steps[steps.length - 1];
      if (step) {
        step.push(child.attributes.name);
      }
    }

    getFields(child, fields, touched, steps, dependencies, isClearbitForm);
  }

  return [fields, touched, steps, dependencies];
};

const validate = (values, step) => {
  const errors = {};
  let valuesToCheck = {};

  if (step) {
    for (let i = 0; i < step.length; i++) {
      const field = step[i];
      valuesToCheck[field] = values[field];
    }
  } else {
    valuesToCheck = values;
  }
  for (const key in valuesToCheck) {
    if (values.hasOwnProperty(key)) {
      let val = values[key];

      switch (key) {
        case 'user_name':
          if (!val?.trim() || val.length <= 0) {
            errors.user_name = 'Invalid user name';
          }
          break;
        case 'first_name':
          if (!val?.trim() || val.length <= 0) {
            errors.first_name = 'Invalid first name';
          }
          break;
        case 'last_name':
          if (!val?.trim() || val.length <= 0) {
            errors.last_name = 'Invalid last name';
          }
          break;
        case 'user_email':
          if (!emailRegex.test(val)) {
            errors.user_email = 'Invalid user email';
          }
          break;
        case 'user_company':
          if (!val?.trim() || val.length <= 0) {
            errors.user_company = 'Invalid user company';
          }
          break;
        case 'user_employees':
          val = parseInt(val);
          if (!val || val <= 0 || isNaN(val)) {
            errors.user_employees = 'Invalid user employees';
          }
          break;
        case 'user_lead_source':
          if (!val || val === '') {
            errors.user_lead_source = 'Invalid lead source';
          }
          break;
        case 'user_lead_source_text':
          if (!val?.trim() || val.length <= 0) {
            errors.user_lead_source_text = 'Invalid lead source';
          }
          break;
        case 'contact_reason':
          if (!val || val === '') {
            errors.contact_reason = 'Invalid contact reason';
          }
          break;
        case 'company_offer_insurance':
          if (!val || val === '') {
            errors.company_offer_insurance =
              'Invalid company issurance offer answer';
          }
          break;
        case 'Last_Lead_Origin__c':
          if (!val || val === '') {
            errors.Last_Lead_Origin__c = 'Please select a session';
          }
          break;
        case 'business_email':
          if (!standardEmail.test(val)) {
            errors.business_email = 'Please use your business email';
          }
          break;
        case 'insurance_date':
          if (valuesToCheck.company_offer_insurance !== 'Yes') {
            break;
          }

          // For Safari and browsers that don't support date field
          if (/\//.test(val)) {
            const data = val.split('/');
            var [month, date, year] = data;
          } else {
            const data = val.split('-');
            var [year, month, date] = data;
          }

          const now = new Date();
          const currentYear = now.getFullYear();
          const currentMonth = now.getMonth() + 1;
          const currentDate = now.getDate();

          year = parseInt(year);
          month = parseInt(month);
          date = parseInt(date);

          if (!year || !month || !date) {
            errors.insurance_date = 'Invalid date. Expected format: mm/dd/yyyy';
          } else if (
            year < currentYear ||
            (year === currentYear && month < currentMonth) ||
            (year === currentYear &&
              month === currentMonth &&
              date <= currentDate)
          ) {
            errors.insurance_date = 'Date already passed';
          } else if (year > 2100) {
            errors.insurance_date = 'Date not covered';
          }

          break;
        case 'user_previous_payroll':
          const userEmployees = valuesToCheck.user_employees
            ? parseInt(valuesToCheck.user_employees)
            : 0;
          if ((!val || val === '') && userEmployees <= 19) {
            errors.user_previous_payroll = 'Invalid previous payroll answer';
          }
          break;
        case 'user_phone':
          if (!val || val === '' || val.length < 9) {
            errors.user_phone = 'Invalid phone number';
          }
          break;
        case 'schedule_demo_datetime':
          if (!val || val.length <= 0) {
            errors.schedule_demo_datetime = 'Please select a date';
          } else if (val.getHours() < 6 || val.getHours() > 17) {
            errors.schedule_demo_datetime = 'Please select a preferred time';
          }
          break;
        case 'Agree_to_policy':
          if (!val || val.length <= 0) {
            errors.Agree_to_policy = 'You must agree to continue';
          }
        case 'Attending_SHRM23':
          if (!val || val.length <= 0) {
            errors.Attending_SHRM23 = 'You must be registered to be eligible';
          }
          break;
        case 'organizationType':
          if (!val || val.length <= 0) {
            errors.organizationType = 'No organization type selected';
          }
          break;
        case 'user_gift_preference':
          if (!val || val.length <= 0) {
            errors.user_gift_preference = 'No gift card selected';
          }
          break;
        case 'Demo_PreQual_Current_Payroll_Provider__c':
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Current_Payroll_Provider__c =
              'No payroll provider specified';
          }
          break;
        case 'Demo_PreQual_Num_and_Type_of_Employees__c':
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Num_and_Type_of_Employees__c =
              'No number of employees specified';
          }
          break;
        case 'Demo_PreQual_Areas_of_Interest__c':
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Areas_of_Interest__c =
              'No pain points specified';
          }
          break;
        case 'Country':
          if (!val || val === '') {
            errors.Country = 'Please select a country';
          }
          break;
        case 'who_referred_you':
          var campaign = getUrlParam('utm_campaign');
          if (campaign === 'partner-referral-accountant' || campaign === 'partner-referral-broker') {
            if (!val || val === '') {
              errors.who_referred_you = 'Please let us know who referred you';
            }
          }
          break;
        default:
      }
    }
  }

  if (values.user_lead_source_text) {
    delete errors.user_lead_source;
  }
  if (values.user_lead_source) {
    delete errors.user_lead_source_text;
  }

  return errors;
};

const googleAdwordsTracking = () => {
  if (window.google_trackConversion) {
    window.google_trackConversion({
      google_conversion_id: 794594069,
      google_conversion_language: 'en',
      google_conversion_format: '3',
      google_conversion_color: 'ffffff',
      google_conversion_label: 'odM0CJb_sIkBEJWW8voC',
      google_remarketing_only: false,
    });
  }
};

const firePPCSitePixels = () => {
  // trigger capterra tracking.
  const capterra_prefix =
    document.location.protocol === 'https:'
      ? 'https://ct.capterra.com'
      : 'http://ct.capterra.com';

  const capterraTrackers = {
    US: ['2119234', '5d388caf8dac5a4cabc7669880b61e59'],
    Australia: ['2300254', '9e3a062ef763eb52ac9807a9004c126b'],
    Canada: ['2300252', 'c68623610552a6dd44ddcd7cd6ce764b'],
    France: ['2300935', 'ed46e90587e8dc86b46d3da2e380198d'],
    NetherlandsBelgium: ['2330553', '40afef419c0b5d980d432cc14ba54a0a'],
    UK: ['2249605', 'd6b52d8415f4856d863b576454387b5b'],
  };

  Object.values(capterraTrackers).forEach(([capterra_vid, capterra_vkey]) => {
    (function () {
      const ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = `${capterra_prefix}/capterra_tracker.js?vid=${capterra_vid}&vkey=${capterra_vkey}`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ct, s);
    })();
  });

  // trigger crozdesk tracking.
  (function () {
    const cdx = document.createElement('script');
    cdx.type = 'text/javascript';
    cdx.async = true;
    cdx.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'
      }trk.crozdesk.com/FC4ZLTASMywvbywebrSQ`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(cdx, s);
  })();
};

let complete = false;

const onError = (e) => {
  alert(
    'The form submission failed. Please refresh the page and try again or contact someone at resources@rippling.com'
  );
};

const submissionHandler = async (
  values,
  router,
  customOnSuccess,
  customOnSuccess2,
  formRef,
  turnOffThankYouPageRedirect,
  redirectTo,
  setIsComplete,
  locale
) => {
  googleAdwordsTracking();
  firePPCSitePixels();
  const cookies = new Cookies();
  const routerPath = router.asPath;
  const isMeetupPage = /^\/meetups/.test(routerPath);
  const isXeroconReceptionPage = /^\/xerocon-reception/.test(routerPath);

  if (isMeetupPage || isXeroconReceptionPage) {
    axios({
      method: 'post',
      url: `${window.location.origin}/api/www-vercel-add-google-sheets-form-submission`,
      data: [
        values.user_name,
        values.user_email,
        values.user_company,
        window.location.pathname.replace(
          isMeetupPage ? '/meetups/' : '/xerocon-reception/',
          ''
        ),
        values.is_rippling_customer || 'No',
      ],
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        complete = true;
        if (setIsComplete) {
          setIsComplete();
        }
      })
      .catch((e) => {
        console.log('e: ', e);
      });

    return;
  }

  const {
    first_name,
    last_name,
    user_name,
    user_email,
    user_company,
    user_employees,
    user_phone,
    user_title,
    user_lead_source,
    who_referred_you,
    user_previous_payroll,
    company_offer_insurance,
    insurance_date,
    schedule_demo_datetime,
    schedule_demo_timezone,
    current_challenges_c,
    contact_reason,
    ur_products,
    ur_topics,
    user_gift_preference,
    organizationType,
    business_email,
    Num_Years_as_Rippling_User__c,
    Wavemakers_Application_Reason__c,
    Last_Lead_Origin__c,
    Append_to_Comments__c,
    State,
    is_new_or_existing_customer,
    Likely_Global_Fit__c
  } = values;

  const hiddenFields = {
    utility: formRef.current?.querySelector('input[name="utility"]'),
    inboundTestVariant: formRef.current?.querySelector(
      'input[name="inboundTestVariant"]'
    ),
    referrer: formRef.current?.querySelector('input[name="referrer"]'),
    initial_landing_page: formRef.current?.querySelector(
      'input[name="initial_landing_page"]'
    ),
    form_category: formRef.current?.querySelector(
      'input[name="Form_Category"]'
    ),
    form_type: formRef.current?.querySelector('input[name="Form_Type__c"]'),
    user_company_type: formRef.current?.querySelector(
      'input[name="company_type"]'
    ),
    video_tour: formRef.current?.querySelector('input[name="video_tour"]'),
    p_name: formRef.current?.querySelector('input[name="p_name"]'),
    Last_Lead_Origin__c: formRef.current?.querySelector(
      'input[name="Last_Lead_Origin__c"]'
    ),
    Product_Updates_Opt_Out__c: formRef.current?.querySelector(
      'input[name="Product_Updates_Opt_Out__c"]'
    ),
    Webinar_Registration_Date__c: formRef.current?.querySelector(
      'input[name="eventDate"]'
    ),
    visitor_ip: formRef.current?.querySelector(
      'input[name="visitor_ip"]'
    ),
    ga_session_id: formRef.current?.querySelector(
      'input[name="ga_session_id"]'
    ),
  };

  let schedule_demo_date;
  let schedule_demo_time;

  if (schedule_demo_datetime) {
    const zeroify = (num) => `0${num}`.slice(-2);

    [schedule_demo_date, schedule_demo_time] = [
      `${zeroify(schedule_demo_datetime.getMonth() + 1)}/${zeroify(
        schedule_demo_datetime.getDate()
      )}/${schedule_demo_datetime.getFullYear()}`,
      `${zeroify(schedule_demo_datetime.getHours())}:${zeroify(
        schedule_demo_datetime.getMinutes()
      )}`,
    ];
  }

  try {
    const savedFormData = cookies.get('saved_form_values');

    const dialCode =
      values.Country_Dialing_Code__c === 1
        ? '+1'
        : values.Country_Dialing_Code__c;

    var formData = {
      first_name: first_name && first_name.trim(),
      last_name: last_name && last_name.trim(),
      user_name: user_name && user_name.trim(),
      user_email:
        (user_email && user_email.trim()) ||
        (business_email && business_email.trim()),
      user_company: user_company && user_company.trim(),
      user_employees: user_employees && parseInt(user_employees),
      user_lead_source: user_lead_source && user_lead_source.trim(),
      who_referred_you: who_referred_you && who_referred_you.trim(),
      user_phone: dialCode
        ? `${dialCode} ${user_phone && user_phone.trim()}`
        : user_phone && user_phone.trim(),
      user_title: user_title &&  user_title.trim(),
      contact_reason: contact_reason && contact_reason.trim(),
      schedule_demo_date,
      schedule_demo_time,
      schedule_demo_timezone,
      ur_products: ur_products?.join(';'),
      ur_topics: ur_topics?.join(';'),
      unityWebinarName: values.unityWebinarName?.join(';'),
      current_challenges_c: current_challenges_c?.join(';'),
      Offer_Health_Insurance__c:
        company_offer_insurance && company_offer_insurance.trim(),
      Medical_Renewal_Date__c_lead: insurance_date && insurance_date.trim(),
      user_previous_payroll:
        user_previous_payroll && user_previous_payroll.trim(),
      user_company_type: hiddenFields.user_company_type
        ? hiddenFields.user_company_type.value
        : undefined,
      user_gift_preference: user_gift_preference && user_gift_preference.trim(),
      organizationType: organizationType && organizationType.trim(),
      video_tour: hiddenFields.video_tour
        ? hiddenFields.video_tour.value
        : undefined,
      p_name: hiddenFields.p_name ? hiddenFields.p_name.value : undefined,
      Last_Lead_Origin__c: hiddenFields.Last_Lead_Origin__c
        ? hiddenFields.Last_Lead_Origin__c.value
        : undefined,
      form_type: hiddenFields.form_type
        ? hiddenFields.form_type.value
        : undefined,
      referrer: hiddenFields.referrer ? hiddenFields.referrer.value : undefined,
      utility: hiddenFields.utility ? hiddenFields.utility.value : undefined,
      inboundTestVariant: hiddenFields.inboundTestVariant
        ? hiddenFields.inboundTestVariant.value
        : undefined,
      initial_landing_page: hiddenFields.initial_landing_page
        ? hiddenFields.initial_landing_page.value
        : undefined,
      experiment_id: hiddenFields.experiment_id
        ? hiddenFields.experiment_id.value
        : undefined,
      variation_id: hiddenFields.variation_id
        ? hiddenFields.variation_id.value
        : undefined,
      audience_id: hiddenFields.audience_id
        ? hiddenFields.audience_id.value
        : undefined,
      event_id: hiddenFields.event_id ? hiddenFields.event_id.value : undefined,
      page_id: hiddenFields.page_id ? hiddenFields.page_id.value : undefined,
      Product_Updates_Opt_Out__c: hiddenFields.Product_Updates_Opt_Out__c
        ? hiddenFields.Product_Updates_Opt_Out__c.value
        : undefined,
      Webinar_Registration_Date__c: hiddenFields.Webinar_Registration_Date__c
        ? dateToDateStr(hiddenFields.Webinar_Registration_Date__c.value)
        : undefined,
      Num_Years_as_Rippling_User__c: Num_Years_as_Rippling_User__c || undefined,
      Wavemakers_Application_Reason__c: Wavemakers_Application_Reason__c || undefined,
      Form_Fill_Referring_URL__c: cookies.get('previous_url') || undefined,
      Append_to_Comments__c:  Append_to_Comments__c || undefined,
      State:  State || undefined,
      is_new_or_existing_customer: is_new_or_existing_customer || undefined,
      Likely_Global_Fit__c: Likely_Global_Fit__c || undefined,
      visitor_ip: hiddenFields.visitor_ip?.value ?? undefined,
      ga_session_id: hiddenFields.ga_session_id?.value ?? undefined
    };
    // Forms that update existing records need all previous values
    // A growth reusable uses Last_Lead_Origin__c === Generic Multi-step form across URLs
    if (
      [
        '/thank-you',
        'thank-you-c',
        '/resources/peo-quiz',
        '/shrm2023/confirmation',
      ].includes(routerPath) ||
      formData.Last_Lead_Origin__c === 'Generic Multi-step form'
    ) {
      if (savedFormData) {
        try {
          if (typeof savedFormData === 'object') {
            for (const key in formData) {
              const val = formData[key];
              const savedVal = savedFormData[key];

              if ((!val && savedVal) || key === 'Form_Fill_Referring_URL__c') {
                formData[key] = savedVal;
              }
            }
          }
        } catch (e) {
          console.log('e: ', e);
        }
      }
    }
  } catch (e) {
    console.log('e: ', e);
    onError();
  }

  let quiz_redirect;

  if (routerPath === '/resources/peo-quiz') {
    const user_employees =
      formData.user_employees && parseInt(formData.user_employees);

    if (user_employees >= 100) {
      quiz_redirect = '2b';
    } else if (user_employees <= 4) {
      if (
        values.w2EmployeesNumOfStates === 'Yes' ||
        [
          'We-plan-to-hire-employees-in-more-than-one-state',
          'We-plan-to-hire-employees-only-in-our-HQ',
        ].includes(values.employeesGrowth6Months)
      ) {
        quiz_redirect = '1a';
      } else {
        quiz_redirect = '2a';
      }
    } else if (user_employees > 4 && user_employees <= 100) {
      if (values.employeesGrowth6Months === 'We-plan-to-downsize') {
        quiz_redirect = '2a';
      } else {
        quiz_redirect = '1a';
      }
    }
  }

  const { hostname } = window.location;
  const existingRipplingCookieExperiments =
    cookies.get('rippling_experiments') || [];

  try {
    var fullName = formData.user_name
      ? formData.user_name.split(' ')
      : [formData.first_name, formData.last_name];
    var refercookieInfo = cookies.get('rippling_refer_user');
    var aclidInfo = cookies.get('aclid');
    var gclidInfo = cookies.get('gclid');
    var fbclidInfo = cookies.get('fbclid');
    var msclkidInfo = cookies.get('msclkid');
    var existingCustomer = cookies.get('ExistingCustomer');
  } catch (e) {
    onError();
  }

  const bodyFormData = new FormData();

  const addToFormData = (key, value) => {
    if (value !== undefined) {
      bodyFormData.append(key, value);
    }
  };

  let classification_redirect;

  if (/^\/tools\/worker-classification-analyzer/.test(routerPath)) {
    const analyzerKeys = [
      'classificationAnalyzerContract',
      'classificationAnalyzerManagement',
      'classificationAnalyzerWork',
      'classificationAnalyzerPay',
      'classificationAnalyzerBenefits',
      'classificationAnalyzerExpenses',
      'classificationAnalyzerEquipment',
      'classificationAnalyzerEmail',
      'classificationAnalyzerReviewCycle',
    ];

    const wcaAnswers = analyzerKeys.reduce(
      (accum, key) => `${accum}${key}:${values[key]};`,
      ''
    );

    const classificationAnalyzerContract = addToFormData(
      'WCA_Answers__c',
      wcaAnswers
    );

    const formValues = Object.keys(values);

    const answeredEmployeeArr = [];
    formValues.forEach((value) => {
      if (values[value].toString().includes('Employee')) {
        answeredEmployeeArr.push(values[value]);
      }
    });

    const userType = existingCustomer ? 'Customer' : 'Prospect';

    if (answeredEmployeeArr?.length >= 2) {
      classification_redirect = 'high-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} High Risk (${userType})`;
    } else if (answeredEmployeeArr?.length === 1) {
      classification_redirect = 'moderate-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} Moderate Risk (${userType})`;
    } else {
      classification_redirect = 'low-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} Low Risk (${userType})`;
    }
  }

  const form_category = hiddenFields.form_category?.value;
  const isQuote = form_category === 'quote';
  var fullName = formData.user_name
    ? formData.user_name.split(' ')
    : [formData.first_name, formData.last_name];
  addToFormData('Email', formData.user_email || formData.business_email);
  addToFormData(
    'FirstName',
    fullName.length > 1 ? fullName.slice(0, -1).join(' ') : fullName[0]
  );
  addToFormData(
    'LastName',
    fullName.length > 1 ? fullName.slice(-1).join(' ') : '[[unknown]]'
  );
  addToFormData('Company', formData.user_company);
  addToFormData('NumberOfEmployees', formData.user_employees);
  addToFormData('Title', formData.user_title);
  addToFormData('ReferrerSource', formData.user_lead_source);
  addToFormData(
    'How_Did_You_Hear_About_Us_free_text__c',
    values.user_lead_source_text
  );
  addToFormData('Referring_Partner__c', formData.who_referred_you);
  addToFormData('LT_Campaign__c', cookies.get('utm_campaign'));
  addToFormData('LT_Content__c', cookies.get('utm_content'));
  addToFormData('LT_Medium__c', cookies.get('utm_medium'));
  addToFormData('LT_Source__c', cookies.get('utm_source'));
  addToFormData('LT_Term__c', cookies.get('utm_term'));
  addToFormData('Product_Interest_3P__c', cookies.get('Product_Interest'));
  addToFormData(
    'Ambassador__Short_Code__c',
    refercookieInfo ? refercookieInfo.short_code : ''
  );
  addToFormData(
    'GetAmbassador__Short_Code__c',
    refercookieInfo ? refercookieInfo.short_code : ''
  );
  addToFormData('GCLID__c', gclidInfo || '');
  addToFormData('FBCLID__c', fbclidInfo || '');
  addToFormData('MSCLKID__c', msclkidInfo || '');
  addToFormData('MVF_Click_ID__c', aclidInfo || '');
  addToFormData('GA4_Client_ID__c', cookies.get('_ga'));
  addToFormData('user_agent', navigator.userAgent || '');
  addToFormData('visitor_ip', formData.visitor_ip || '');
  addToFormData('ga_session_id', formData.ga_session_id || '');
  addToFormData('Has_Payroll__c', formData.user_previous_payroll || 'False');
  addToFormData('Gift_Choice__c', formData.user_gift_preference);
  addToFormData('request_quote', isQuote || '');
  addToFormData('Phone', formData.user_phone);
  addToFormData('Country', values.Country);
  addToFormData('Company_Type_Detail__c', formData.user_company_type);
  addToFormData(
    'Offer_Health_Insurance__c',
    formData.Offer_Health_Insurance__c
  );
  addToFormData(
    'Medical_Renewal_Date__c_lead',
    formData.Medical_Renewal_Date__c_lead
  );
  addToFormData('video_tour', formData.video_tour);
  addToFormData('utilityBetaandResearchTopicText', formData.ur_topics);
  addToFormData('utilityBetaandResearchProductTextArea', formData.ur_products);
  addToFormData('unityWebinarName', formData.unityWebinarName);
  addToFormData(
    'utilityGiftCardIncentive',
    cookies.get('homepage_100dollar_exit_popup_seen')
  );
  addToFormData(
    'W2_employees_in_more_than_one_state__c',
    values.w2EmployeesNumOfStates
  );
  addToFormData(
    'Next_6_months_for_employee_growth__c',
    values.employeesGrowth6Months
  );
  addToFormData(
    'Offer_employees_medical_insurance__c',
    values.offerMedicalInsurance
  );
  addToFormData(
    'How_compliance_for_HR_is_handled__c',
    values.ensureComplianceWithPayrollLaws
  );
  addToFormData('Last_Lead_Origin__c', values.Last_Lead_Origin__c);
  addToFormData('Marketo_PEO_Quiz_Result__c', quiz_redirect);
  addToFormData('organizationType', formData.organizationType);
  addToFormData('p_name', formData.p_name);
  addToFormData('Contact_Us_Reason__c', formData.contact_reason);
  addToFormData('Demo_Request_Meeting_Date__c', formData.schedule_demo_date);
  addToFormData('Demo_Request_Meeting_Time__c', formData.schedule_demo_time);
  addToFormData(
    'Demo_Request_Meeting_Time_Zone__c',
    formData.schedule_demo_timezone
  );
  addToFormData('current_challenges_c', formData.current_challenges_c);
  addToFormData(
    'Demo_PreQual_Areas_of_Interest__c',
    values.Demo_PreQual_Areas_of_Interest__c
  );
  addToFormData(
    'Demo_PreQual_Current_Payroll_Provider__c',
    values.Demo_PreQual_Current_Payroll_Provider__c
  );
  addToFormData(
    'Demo_PreQual_Num_and_Type_of_Employees__c',
    values.Demo_PreQual_Num_and_Type_of_Employees__c
  );
  addToFormData('inboundTestVariant', formData.inboundTestVariant);
  addToFormData('utility', formData.utility);
  addToFormData('Last_Lead_Origin__c', formData.Last_Lead_Origin__c);
  addToFormData('Form_Type__c', formData.form_type);
  addToFormData(
    'Form_Fill_Referring_URL__c',
    formData.Form_Fill_Referring_URL__c
  );
  addToFormData('referrer_url__c', formData.referrer);
  addToFormData('Landing_Page__c', formData.initial_landing_page);
  addToFormData(
    'Product_Updates_Opt_Out__c',
    formData.Product_Updates_Opt_Out__c
  );
  addToFormData(
    'Webinar_Registration_Date__c',
    formData.Webinar_Registration_Date__c
  );
  addToFormData(
    'Num_Years_as_Rippling_User__c',
    formData.Num_Years_as_Rippling_User__c
  );
  addToFormData(
    'Wavemakers_Application_Reason__c',
    formData.Wavemakers_Application_Reason__c
  );
  addToFormData(
    'Append_to_Comments__c',
    formData.Append_to_Comments__c
  );
  addToFormData(
    'State',
    formData.State
  );
  addToFormData(
    'is_new_or_existing_customer',
    formData.is_new_or_existing_customer
  );
  addToFormData(
    'Likely_Global_Fit__c',
    formData.Likely_Global_Fit__c
  );

  const experimentStr = getOptimizelyExperimentsFromCookies(cookies);

  addToFormData('Optimizely_Experiment_ID__c', experimentStr);

  const eventKeys = ['form_fill'];

  let secondaryEventName = '';

  if (formData.Last_Lead_Origin__c === 'Demo Request - Exit Intent Modal') {
    secondaryEventName = 'exit_intent_modal';
  } else if (form_category === 'demo' || form_category === 'partner') {
    secondaryEventName = 'demo_request';
  } else if (form_category === 'quote') {
    secondaryEventName = 'quote_request';
  } else if (
    form_category === 'tour' &&
    window.location.pathname === '/thank-you'
  ) {
    secondaryEventName = 'demo_date_request';
  } else if (form_category === 'tour') {
    secondaryEventName = 'tour_request';
  } else if (form_category === 'content') {
    secondaryEventName = 'content_request';
  }

  eventKeys.push(secondaryEventName);

  const optimizelyFormData = handleOptimizelyGTMTracking(eventKeys);

  try {
    Object.entries(optimizelyFormData).forEach(([key, val]) => {
      if (key) {
        addToFormData(key, val.join(', '));
      }
    });
  } catch (e) {
    console.log('e: ', e);
  }

  const fieldsAsObject = formDataToObject(bodyFormData);
  const lastFormSubmit = cookies.get('last_form_submit');

  postToOpenpriseProxy({ formData: fieldsAsObject, lastFormSubmit });

  const marketoPromise = axios({
    method: 'post',
    url: 'https://app.rippling.com/api/growth/request_demo',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  const responses = await Promise.allSettled([marketoPromise]);

  responses.forEach((res) => {
    if (res.status === 'rejected') {
      console.error('e: ', res.reason);
    }
  });

  cookies.set('last_form_submit', new Date().getTime(), {
    expires: getXHoursFromNow(0.0833),
  });

  complete = true;
  if (setIsComplete) {
    setIsComplete();
  }

  if (amplitude && amplitude.getInstance) {
    const eventProperties = {};
    eventProperties.Name =
      fullName.length > 1 ? fullName.slice(0, -1).join(' ') : fullName[0];
    if (formData.user_lead_source) {
      eventProperties.HDYHAU = formData.user_lead_source;
    }
    if (formData.user_company) {
      eventProperties.Company = formData.user_company;
    }
    if (formData.user_email) {
      eventProperties.Email = formData.user_email;
    }
    if (formData.user_phone) {
      eventProperties.Phone = formData.user_phone;
    }
    if (formData.user_employees) {
      eventProperties['Number of Employees'] = formData.user_employees;
      eventProperties['Market Segment'] =
        formData.user_employees > 25 ? 'MM' : 'SMB';
    }
    if (formData.Last_Lead_Origin__c) {
      eventProperties['Last Lead Origin'] = formData.Last_Lead_Origin__c;
    }
    if (formData.form_type) {
      eventProperties['Form Type'] = formData.form_type;
    }

    if (
      !/@testrippling.com$/.test(formData.user_email) &&
      window.location.hostname === 'www.rippling.com'
    ) {
      amplitude
        .getInstance()
        .logEvent(
          `Form Submit - ${capitalize(form_category)}`,
          eventProperties
        );
    }
  }

  axios({
    method: 'get',
    url: `${window.location.origin
      }/api/www-vercel-optimizely-events?eventKeys=${eventKeys.join(',')}`,
  })
    .then(() => { })
    .catch((e) => {
      console.log('e: ', e);
    });

  window.dataLayer.push({
    event: 'Form Submit (Success)',
    formCompanyName: formData.user_company,
    formCategory: form_category,
    formType: formData.form_type,
    formFullName: fullName,
    formLeadSource: formData.user_lead_source,
    formNumberOfEmployees: formData.user_employees,
    formWorkEmail: formData.user_email,
    formWorkPhone: formData.user_phone,
    sheduleDemoDate: formData.schedule_demo_date,
  });

  // Deprecate in favor of the 2nd
  if (customOnSuccess) {
    customOnSuccess();
  }

  if (customOnSuccess2) {
    new Function('values', customOnSuccess2)({
      formData,
      amplitude,
      router,
      cookies,
    });
  }

  try {
    if (window.clearbit && window.clearbit.identify) {
      window.clearbit.identify(formData.user_email, {
        email: formData.user_email,
      });
    }
  } catch (e) {
    console.log('e: ', e);
  }

  cookies.set('thankyou_email', formData.user_email, {
    path: '/',
    domain: window.location.hostname,
    expires: get30Days(),
  });

  if (formData.Last_Lead_Origin__c === 'Generic Multi-step form') {
    cookies.set('saved_form_values', formData, {
      path: '/',
      domain: hostname,
      expires: getXHoursFromNow(48),
    });
  }

  if (formData.schedule_demo_date) {
    if (['/thank-you', '/thank-you-c'].includes(routerPath)) {
      const scheduleMessage = document.querySelector('.scheduleMessage');
      const formStep = document.querySelector('form .multistepForm_step');

      formStep.classList.add('-hide');

      if (scheduleMessage) {
        scheduleMessage.innerText = getDemoDateTime(formData, { locale });
      }

      const scheduleMessageWrapper = document.querySelector(
        '.scheduleMessage_wrapper'
      );
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    } else if (routerPath === '/shrm2023/confirmation') {
      const formStep = document.querySelector('form .multistepForm_step');

      formStep.classList.add('-hide');

      const scheduleMessageWrapper = document.querySelector(
        '.scheduleMessage_wrapper'
      );
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    } else {
      const formStep = [
        ...document.querySelectorAll('form .multistepForm_step'),
      ];
      formStep.forEach((step) => {
        step.classList.add('-hide');
      });

      const scheduleMessageWrapper = document.querySelector(
        '.scheduleMessage_wrapper'
      );
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    }
  }

  const hrTech = routerPath === '/hr-tech';
  if (hrTech) {
    values = {
      firstname: formData.first_name,
      lastname: formData.last_name,
      email: formData.user_email,
      company: formData.user_company,
    };

    // eslint-disable-next-line no-undef
    ChiliPiper.submit('rippling', 'hr-tech-field-event', {
      map: true,
      lead: values,
    });
    return false;
  }

  const isStorylaneForm = routerPath === '/interactive-tour';
  if (isStorylaneForm) {
    amplitude.getInstance().logEvent('Form Submit - Interactive Tour');

    // eslint-disable-next-line no-undef
    launchStorylaneDemo();
  } else if (!turnOffThankYouPageRedirect) {
    if (quiz_redirect) {
      quiz_redirect = quiz_redirect === '1a' ? '1' : quiz_redirect;

      router.push(`/hr-quiz-results-${quiz_redirect}`);
    } else if (classification_redirect) {
      const path = existingCustomer
        ? `/tools/worker-classification-analyzer-${classification_redirect}`
        : `/tools/worker-classification-analyzer-results-${classification_redirect}`;

      router.push(path);
    } else {
      cookies.set('saved_form_values', formData, {
        path: '/',
        domain: hostname,
        expires: getXHoursFromNow(48),
      });
      router.push(redirectTo || '/thank-you');
    }
  }
};

const simpleSubmissionHandler = (
  values,
  router,
  redirectTo,
  enableClearbitPeopleSearch,
  formRef
) => {
  const redirectURL = redirectTo || null;
  const cookies = new Cookies();
  cookies.set('user_email', values.user_email || values.business_email, {
    path: '/',
    domain: window.location.hostname,
    expires: getXHoursFromNow(1),
  });

  if (enableClearbitPeopleSearch) {
    const halfMinuteOfAnHourFraction = 0.00833;
    const cookieSettings = {
      path: '/',
      expires: getXHoursFromNow(halfMinuteOfAnHourFraction),
    };

    Object.entries(values).forEach(([key, val]) => {
      if (!val) {
        return;
      }

      cookies.set(`clearbit_${key}`, val, cookieSettings);
    });
  }

  const { pathname } = window.location;

  amplitude.getInstance().logEvent('Click - Multistep Form', { URL: pathname });

  window.dataLayer.push({
    event: 'multistep_form_start',
    formCategory: 'simple',
    formType: window.location.pathname,
  });

  const cbReveal = window.dataLayer.find((item) => !!item?.reveal);

  if (cbReveal?.reveal?.company) {
    const formTypeHiddenEl = formRef.current?.querySelector(
      'input[name="Form_Type__c"]'
    );

    const formType = !!formTypeHiddenEl ? formTypeHiddenEl.value : undefined;

    const bodyFormData = new FormData();
    bodyFormData.append('Email', values?.user_email || values?.business_email);
    bodyFormData.append('p_name', 'simple-form');
    bodyFormData.append('Form_Type__c', formType);

    const fieldsAsObject = formDataToObject(bodyFormData);

    const lastFormSubmit = cookies.get('last_form_submit');

    // The following calls are not supposed to halt redirecting to the appropriate form.
    // If they fail, log the error to the console for debuggining purposes
    postToOpenpriseProxy({ formData: fieldsAsObject, lastFormSubmit }).catch(
      (e) => {
        console.error('Openprise submission error: ', e);
      }
    );

    axios({
      method: 'post',
      url: 'https://app.rippling.com/api/growth/request_demo',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).catch((e) => {
      console.error('Marketo submission error: ', e);
    });
  }

  redirectTo = redirectURL || '/request-demo';

  if (['/peo', '/peo-02', '/peo-03'].includes(pathname)) {
    redirectTo = '/peo-request-demo';
  } else if (['/accountants', '/accountants-02'].includes(pathname)) {
    redirectTo = '/accountants-demo-request';
  } else if (['/brokers', '/brokers-02'].includes(pathname)) {
    redirectTo = '/brokers-demo-request';
  } else if (['/venture-firms-and-accelerators'].includes(pathname)) {
    redirectTo = '/venture-firms-and-accelerators-demo-request';
  } else if (['/developers'].includes(pathname)) {
    redirectTo = '/developers-partner-form';
  }

  router.push(redirectTo);
};

const handleIsPartnerPageListing = (fields, touched) => {
  const isPartnerPageListing =
    getUrlParam('utm_campaign') === 'partner_page_listing';

  if (isPartnerPageListing) {
    const utmMedium = getUrlParam('utm_medium');
    const utmSource = getUrlParam('utm_source');

    if (utmMedium) {
      const userLeadSource = {
        accountant: 'Accountant',
        broker: 'Insurance Broker',
      };

      fields.user_lead_source = userLeadSource[utmMedium];
      touched.user_lead_source = true;
    }

    if (utmSource) {
      const whoReferredYou = {
        basis365: 'Basis 365',
        'aduro-advisors': 'Aduro Advisors',
        aeis: 'AEIS Advisors',
        akw: 'AKW Financial',
        alium: 'Alium',
        alliant: 'Alliant',
        bdo: 'BDO',
        bookkeeper360: 'Bookkeeper360',
        cfa: 'CFA Insurance',
        countsy: 'Countsy',
        finvisor: 'Finvisor',
        formationfinancial: 'Formation Financial',
        'kbi-benefits': 'KBI Consulting',
        'kruze-consulting': 'Kruze Consulting',
        launchways: 'Launchways',
        rfp: 'RFP',
        guideline: '',
        capitolbenefits: 'Capitol Benefits',
      };

      fields.who_referred_you = whoReferredYou[utmSource];
      touched.who_referred_you = true;
    }
  }
};

function disableEnterSubmitOnKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const hideField = (child) => {
  const { name: fieldName } = child.props?.attributes || {};

  if (
    ![
      'user_email',
      'user_lead_source',
      'who_referred_you',
      'user_previous_payroll',
    ].includes(fieldName)
  ) {
    if (!/\shidden/.test(child.props.attributes.className)) {
      child.props.attributes.className += ' hidden';
    }
  }
};

const showField = (child) => {
  if (child.props.attributes.className) {
    child.props.attributes.className = child.props.attributes?.className.replace(
      /\shidden/g,
      ''
    );
  }
};

let formStarted = false;
let reportedClearbitRequestToGa = false;

class BasicForm extends React.Component {
  constructor(props) {
    super(props);

    const cookies = new Cookies();

    // Hide headquarters if it is set from Clearbit
    this.countrySetFromClearbit = cookies.get('clearbit_Country');

    const [fields, touched, steps, dependencies] = getFields(
      this.props,
      {},
      {},
      [],
      [],
      this.props.attributes.enableClearbitEmailSearch
    );

    this.fields = fields;
    this.touched = touched;
    this.steps = steps;
    this.dependencies = dependencies;

    // The following 2 flags are used to stop a submit from happening on the simple form before the
    // async requests resolve on field blur. They will manually submit after resolution.
    this.isFetchingClearbitData = false;
    this.wasSubmittingForm = false;
    this.wasBlurringEmailField = false;
    this.emailBlurs = 0;

    this.state = {
      isComplete: false,
      referrer: '',
      loadClearbitUser: true,
    };

    const hiddenFields = this.props.attributes.hiddenInputs;
    this.hiddenFields = [];
    this.referralField = null;
    this.formRef = React.createRef();

    for (const key in hiddenFields) {
      const value = hiddenFields[key];
      this.hiddenFields.push(
        <input type="hidden" name={key} key={key} value={value} />
      );
    }
  }

  componentDidMount() {
    handleIsPartnerPageListing(this.fields, this.touched);
    this.setState({ referrer: window.document.referrer });
  }

  componentWillUnmount() { }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const { attributes, children, locale } = this.props;
    const {
      className,
      data,
      customOnSuccess,
      customOnSuccess2,
      formType,
      disableEnterSubmit,
      isSimpleSubmit,
      turnOffThankYouPageRedirect,
      redirectTo,
      initialValues,
    } = attributes;
    const enableClearbitPeopleSearch = attributes.enableClearbitEmailSearch;
    const cookies = new Cookies();
    const initialLandingPage = cookies.get('initial_landing_page');
    const { isComplete, referrer } = this.state;

    return (
      <Formik
        initialValues={
          initialValues
            ? Object.assign(this.fields, initialValues)
            : this.fields
        }
        initialTouched={this.touched}
        validateOnChange={true}
        validate={validate}
        onSubmit={
          isSimpleSubmit
            ? (values) => {
              if (this.isFetchingClearbitData) {
                this.wasSubmittingForm = true;
                return;
              }

              simpleSubmissionHandler(
                values,
                this.props.router,
                redirectTo,
                enableClearbitPeopleSearch,
                this.formRef
              );
            }
            : async (values) => {
              await submissionHandler(
                values,
                this.props.router,
                customOnSuccess,
                customOnSuccess2,
                this.formRef,
                turnOffThankYouPageRedirect,
                redirectTo,
                () => this.setState({ isComplete: true }),
                locale
              );
            }
        }
      >
        {({
          errors,
          touched,
          setValues,
          submitForm,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          const [activeIndex, setActiveIndex] = useState(0);
          const [emailCheckedByClearbit, setEmailCheckedByClearbit] = useState(
            values.user_email
          );
          const [emailBlurs, setEmailBlurs] = useState(this.emailBlurs);


          const clearbitKeys = {};
          const clearbitEnrichment = async (val) => {
            this.isFetchingClearbitData = true;
            handleChange(val);

            await throttledFetchUserFormData(val, (clearbitData) => {
              // Unfortunately we need a state update to set the isFetchingClearbitData flag to false
              // after a failed clearbit call
              if (!clearbitData) {
                setValues({ ...values });
                return;
              }
              if (!reportedClearbitRequestToGa) {
                reportedClearbitRequestToGa = true;


                Object.keys(clearbitData).forEach(
                  (clearbitKey) => (clearbitKeys[clearbitKey] = true)
                );
                window.dataLayer.push({
                  event: 'clearbit_form_enrichment',
                  ...clearbitKeys,
                });
              }

              if (clearbitData.Country) {
                this.countrySetFromClearbit = true;
              }

              setValues({ ...values, ...clearbitData, enriched:Object.keys(clearbitData)});
            });
          };
        
          useEffect(() => {
            this.isFetchingClearbitData = false;

            if (this.wasSubmittingForm) {
              submitForm();
            }

            if (this.wasBlurringEmailField) {
              setEmailCheckedByClearbit(true);
            }
          }, [values]);


          useEffect(() => {
            const fn = async () => {
              if (cookies.get('user_email')) {
                await clearbitEnrichment(cookies.get('user_email'));
                setEmailCheckedByClearbit(true);
              }
            };

            fn();
          }, []);

          useEffect(() => {
            const storedFastTrackUser = JSON.parse(sessionStorage.getItem('payroll-fasttrack-questionnaire'));
            if (storedFastTrackUser !== null) {
              const { 
                firstName, 
                lastName, 
                email, 
                companyName, 
                countryCode,
                nationalNumber,
                questionnaireTextBlob
              } = storedFastTrackUser;

              const fullName = `${firstName || ''} ${lastName || ''}`.trim();
              const updatedCountryCode = `+${countryCode}`;
              const fastTrackValues = {
                user_name: fullName ?? null,
                user_email: email ?? null,
                user_company: companyName ?? null,
                Country_Dialing_Code__c: updatedCountryCode ?? null,
                user_phone: nationalNumber ?? null,
                Append_to_Comments__c: questionnaireTextBlob ?? null
              };
              setValues({...values, ...fastTrackValues });
            } 
          }, []);  

          useEffect(() => {
            if (Object.keys(touched).length > 1) {
              axios({
                method: 'get',
                url: `${window.location.origin}/api/www-vercel-optimizely-events?eventKeys=form_started`,
              })
                .then(() => { })
                .catch((e) => {
                  console.log('e: ', e);
                });
            }
          }, [Object.keys(touched).length]);

          let stepIndex = 0;
          const isMultistep = !!this.steps.length;

          if (
            isComplete &&
            isMultistep &&
            activeIndex === this.steps.length - 2
          ) {
            setActiveIndex(activeIndex + 1);
          }

          const childrenWithProps = recursiveMap(children, (child, i) => {
            let props;

            if (child.props.attributes?.alias === 'Step') {
              props = {
                ...props,
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: {
                    'data-active': i === activeIndex || undefined,
                  },
                },
              };

              stepIndex = i;
            }

            if (/form_next/.test(child.props.attributes?.className)) {
              // Check if the current element (Next Button) also has the "step-partially-complete" class
              const hasStepPartiallyComplete = /step-partially-complete/.test(child.props.attributes?.className);
              const action = hasStepPartiallyComplete ? 'some' : 'every';
              const everyStepTouched = this.steps[activeIndex][action]((field) =>
                Array.isArray(values[field]) ? values[field].length : values[field]
              );
          
              const isClickable =
                  everyStepTouched &&
                  !Object.keys(errors).find((error) =>
                      this.steps[activeIndex].find((field) => field === error)
                  );
              props = {
                  ...child.props,
                  propsOnClick: () => {
                      if (isClickable) {
                          setActiveIndex(activeIndex + 1);
                      }
                  },
                  attributes: {
                      ...child.props.attributes,
                      data: {
                          disabled: !isClickable || undefined,
                      },
                  },
              };
          }

            if (/form_prev/.test(child.props.attributes?.className)) {
              props = {
                ...child.props,
                propsOnClick: () => {
                  setActiveIndex(activeIndex - 1);
                },
              };
            }

            if (/form_progress/.test(child.props.attributes?.className)) {
              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: {
                    style: {
                      transform: `scaleX(${isSubmitting || isComplete
                          ? 1
                          : activeIndex / this.steps.length
                        })`,
                    },
                  },
                },
              };
            }

            if (/form_indicator/.test(child.props.attributes?.className)) {
              const data = { 'data-active': true };

              for (let index = 0; index < activeIndex; index++) {
                data[`data-active${index + 2}`] = true;
              }

              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data,
                },
              };
            }

            if (
              /form_success_message/.test(child.props.attributes?.className)
            ) {
              if (isComplete) {
                props = {
                  ...child.props,
                  attributes: {
                    ...child.props.attributes,
                    innerContent: child.props.attributes.innerContent.replace(
                      ':',
                      `: ${cookies.get('thankyou_email')}`
                    ),
                  },
                };
              }
            }

            if (child.props.name === 'submit') {
              const isFormPartiallyComplete = /form-partially-complete/.test(child.props.attributes?.className);
            
              const everyFieldTouched = Object.keys(values).every(
                (key) => touched[key]
              );
            
              const everyFieldHasValues = Object.keys(values).every((key) =>
                Array.isArray(values[key]) ? values[key].length : values[key]
              );
            
              // New logic to handle the step-partially-complete step
              const atLeastOneFieldTouched = Object.keys(values).some(
                (key) => touched[key]
              );
            
              const isClickable = isFormPartiallyComplete
                ? atLeastOneFieldTouched && !Object.keys(errors).length // At least one field touched for step-partially-complete, no errors
                : (everyFieldTouched && !Object.keys(errors).length) || everyFieldHasValues; // All fields touched, no errors, or all fields have values for other steps
            
              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: {
                    disabled:
                      isSubmitting ||
                      (this.steps.length > 2 && !isClickable) ||
                      undefined,
                  },
                },
              };
            }            

            if (
              ['radios', 'field', 'field2', 'select', 'date-picker'].includes(
                child.props.name
              )
            ) {
              props = {
                handleChange,
                handleBlur,
                setValues,
                values,
                errors,
                formRef: this.formRef,
                touched,
                isActive: this.stepIndex === this.activeIndex,
              };

              const { name: fieldName } = child.props?.attributes || {};
              
              if (enableClearbitPeopleSearch) {
                if (fieldName === 'user_email') {
                  props.handleChange = async (val) => {
                    await clearbitEnrichment(val);
                  };

                  props.handleBlur = async (val) => {
                    this.wasBlurringEmailField = true;
                    handleBlur(val);

                    if (!this.isFetchingClearbitData) {
                      setEmailCheckedByClearbit(true);
                    }

                    // increment the number of email blurs
                    setEmailBlurs(emailBlurs + 1);
                  };
                }
                // Default fields to show + already hidden conditional fields
                hideField(child);
          
                if (emailCheckedByClearbit) {
                  if (
                    (values[fieldName] && !values.enriched?.includes(fieldName)) ||
                    clearbitKeys[fieldName] === false || 
                    emailBlurs > 1 ||
                    // If user_phone is not set, show Country_Dialing_Code__c even if it's filled. For
                    // all other fields, show the field if it is not shown
                    (['Country_Dialing_Code__c'].includes(fieldName) &&
                      !values.user_phone) ||
                    (!['Country_Dialing_Code__c'].includes(fieldName) &&
                      !values[fieldName]) ||
                    // Only hide Country if it is set by Clearbit. Show if set by user's geo
                    (fieldName === 'Country' && !this.countrySetFromClearbit)
                  ) {
                    showField(child);
                  }
                }
              }
            

              // sometimes we use radios as buttons... AND use them to trigger form_next
              if (/form_next/.test(child.props.attributes?.className)) {
                const handleFormNextOnChange = (val) => {
                  if (handleChange) {
                    handleChange(val);
                  }
                  setActiveIndex(activeIndex + 1);
                };
                props.handleChange = handleFormNextOnChange;
                // we use this prop to signal that the radio set is used as a button and one should always appear as a "default" option.
                props.renderedAsFormButtons = true;
              }
            }

            if (React.isValidElement(child)) {
              return React.cloneElement(child, props);
            }
            return child;
          });

          const isError = Object.keys(touched).find((key) => errors[key]);

          if (isError) {
            let formTypeC = this.props.attributes.hiddenInputs?.Form_Type__c;
            formTypeC =
              formType === 'simple' ? window.location.pathname : formTypeC;

            window.dataLayer.push({
              event: 'form_error',
              formCategory: formType,
              formType: formTypeC,
            });
          }

          return (
            <form
              className={`form ${classnames({
                [className]: className,
                '-error': Object.keys(errors).length,
              })}`}
              ref={this.formRef}
              {...data}
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
              onKeyDown={
                disableEnterSubmit ? disableEnterSubmitOnKeyDown : undefined
              }
              onClick={() => {
                let formTypeC = this.props.attributes.hiddenInputs
                  ?.Form_Type__c;
                formTypeC =
                  formType === 'simple' ? window.location.pathname : formTypeC;

                if (!formStarted) {
                  window.dataLayer.push({
                    event: 'form_started',
                    formCategory: formType,
                    formType: formTypeC,
                  });

                  formStarted = true;
                }
              }}
            >
              <input name="Form_Category" type="hidden" value={formType} />
              {this.hiddenFields}
              {this.referralField}
              <input name="referrer" type="hidden" value={referrer} />
              <input
                name="initial_landing_page"
                type="hidden"
                value={initialLandingPage}
              />
              {childrenWithProps}
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withRouter(BasicForm);
